<template>
    <div class="root-wrapper">
        <Sidebar />
        <div class="main-container">
            <Navbar />
            <AppMain />
        </div>
    </div>
</template>

<script>
    import Sidebar from './Sidebar'
    import Navbar from './Navbar'
    import AppMain from './AppMain'
    export default {
        name: 'MyBlogRootWrapper',
        components: {
            Sidebar,
            Navbar,
            AppMain
        },
        data() {
            return {

            };
        },

        mounted() {

        },

        methods: {

        },
    };
</script>

<style lang="less" scoped>
    .root-wrapper {
        display: flex;
        .main-container {
            flex: 1;
            transition: all 0.3s;
        }
    }
</style>