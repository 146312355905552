<template>
    <div class="rootSidebar" :style="{width: collapsed ? '79px' : '256px'}">
        <a-menu :default-selected-keys="[0]" :selectedKeys="[vxMenuSelectIndex]" mode="inline" :inline-collapsed="collapsed" @click="changeMenu">
            <template v-for="(item, index) in adminRouter">
                <a-menu-item :key="index" v-if="item.meta.show" :class="{'ant-menu-item-selected': $route.path.split('/')[2] == item.path}">
                    <a-icon :type="item.meta.icon" />
                    <span>{{ item.meta.desc === '发布文章' ? (editStatus ? '编辑文章' : '发布文章') : item.meta.desc }}</span>
                </a-menu-item>
            </template>
        </a-menu>
    </div>
</template>

<script>
    import {mapGetters, mapMutations, mapState} from 'vuex';
    import adminRouter from '../router/adminRouter'
    export default {
        data() {
            return {
                adminRouter,
                selectedKeys: [],
            };
        },
        computed: {
            ...mapGetters({collapsed: 'rootSidebar'}),
            ...mapState({vxMenuSelectIndex: state => state.settings.menuSelectIndex}),
            editStatus() {
                return this.$route.query.articleId ? true : false
            }
        },
        methods: {
            ...mapMutations({vxChangeMenuIndex: 'settings/changeMenuIndex'}),
            changeMenu(item) {
                this.$router.replace({name: adminRouter[item.key].name})
                this.vxChangeMenuIndex(item.key)

            }
        },
        mounted() {
            console.log(this.$route.path);
        }
    };
</script>

<style lang="less" scoped>
    .rootSidebar {
        min-height: 100vh;
        background-color: #fff;
        transition: all 0.3s;
    }
</style>
