<template>
    <section class="app-main">
        <transition name="fade-transform" mode="out-in">
            哈哈哈哈
            <router-view :key="key" />
        </transition>
    </section>
</template>

<script>
    export default {
        name: 'AppMain',
        computed: {
            key() {
                return this.$route.path
            }
        }
    }
</script>

<style lang="less" >
    .app-main {
        padding: 10px;
    }
    .app-main-card {
        min-height: 93vh;
        padding: 10px;
        border-radius: 6px;
        background-color: #fff;
    }
</style>
