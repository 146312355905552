<template>
    <div class="BlogRootNavbar">
        <div class="operator-con">
            <a-button type="primary" class="collapsed-btn" @click="toggleCollapsed">
                <a-icon :type="collapsed ? 'menu-unfold' : 'menu-fold'" />
            </a-button>
            <a-breadcrumb>
                <a-breadcrumb-item><a @click="goToHome">Home</a></a-breadcrumb-item>
                <a-breadcrumb-item v-if="vxMenuSelect.meta.title != 'Home'"
                    ><a @click="$router.replace({name: vxMenuSelect.name})">{{ vxMenuSelect.meta.title }}</a></a-breadcrumb-item
                >
            </a-breadcrumb>
        </div>
        <div class="user-con">
            <a-dropdown class="dropdown" overlayClassName="root-dropdown">
                <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
                    <img src="https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif?imageView2/1/w/80/h/80" alt="" />
                    <a-icon type="caret-down" />
                </a>
                <a-menu slot="overlay" class="drop-menu">
                    <a-menu-item class="root-ant-dropdown-menu-item">
                        <a href="javascript:;">Home</a>
                    </a-menu-item>
                    <a-menu-item class="root-ant-dropdown-menu-item">
                        <a href="javascript:;">Github</a>
                    </a-menu-item>
                    <a-menu-item class="root-log-out root-ant-dropdown-menu-item">
                        <a href="javascript:;" @click="rootLoginOut">Log Out</a>
                    </a-menu-item>
                </a-menu>
            </a-dropdown>
        </div>
    </div>
</template>

<script>
    import {mapActions, mapGetters, mapMutations} from 'vuex';
    export default {
        name: 'BlogRootNavbar',

        data() {
            return {
                collapsed: false,
            };
        },

        computed: {
            ...mapGetters({vxMenuSelect: 'menuSelect'})
        },

        methods: {
            ...mapMutations({vxChangeRootSide: 'settings/changeRootSide', vxChangeMenuIndex: 'settings/changeMenuIndex'}),
            ...mapActions({vxRootLoginOut: 'root/rootLoginOut'}),
            toggleCollapsed() {
                this.collapsed = !this.collapsed;
                this.vxChangeRootSide(this.collapsed)
            },
            goToHome() {
                this.vxChangeMenuIndex(0)
                this.$router.push({name: 'rootHome'})
            },
            rootLoginOut() {
                this.vxRootLoginOut()
                this.$router.push({name: 'root-login'})
            }
        },
    };
</script>

<style lang="less" scoped>
    .BlogRootNavbar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 50px;
        width: 100%;
        padding: 15px;
        box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
        background-color: #fff;
        .operator-con {
            display: flex;
            align-items: center;
            .collapsed-btn {
                margin-right: 20px;
            }
        }
        .user-con {
            position: relative;
            // .dropdown {
            //     position: absolute;
            //     top: 50px;
            //     right: 0;
            // }
            .dropdown-open {
                background: red;
            }

            img {
                width: 40px;
                height: 40px;
                border-radius: 6px;
                margin-right: 5px;
            }
            .drop-menu {
                padding: 0 20px;
            }
        }
    }
</style>